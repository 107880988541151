<template>
  <div class="fill-height " style="position:relative;">
    <div class="navbar pb-3" :class="!$vuetify.rtl ? 'rtl_navbar' : 'ltr_navbar'">
      <div class="d-flex justify-space-between  fill-height flex-column">
        <div>
          <div class="logoArea">
            <router-link :to="{name:'dashboard'}">
              <img src="../../assets/logo/whiteLogo.webp" alt="" style="width: 70%" v-if="getPlatformInfo('id') == 3">
              <p v-else-if="getPlatformInfo('id')" style="color: white;text-align: center">{{ getPlatformTitle() }}</p>
            </router-link>
          </div>
          <router-link :to="{name:'setting'}">
            <div class="avatarBox">
              <div class="avatarArea">
                <div class="imageArea" v-if="profile">
                  <img
                      :src=" profile.avatar"
                      v-if="profile.avatar"
                      width="100%"
                      alt="">
                  <img
                      v-else-if="profile.gender"
                      width="100%"
                      :src="require(`../../assets/gender/${profile.gender}.svg`)">
                  <img
                      v-else
                      width="100%"
                      :src="require(`../../assets/gender/OTHER.svg`)">
                </div>
              </div>
              <!--              <div class="settingBox">-->
              <!--                <img src="../../assets/icons/setting.svg" alt="">-->
              <!--              </div>-->
            </div>
          </router-link>
          <div class="menuItemsArea mt-7">
            <div class="menuItems">
              <!--              <router-link :to="{name:'dashboard'}" style="text-decoration: none">-->
              <!--                <div class="menuItem"-->
              <!--                     :class="prepareActiveRoute({name:'dashboard'} )">-->
              <!--                  <div class="d-flex">-->
              <!--                    <img src="../../assets/icons/dashboard.svg" style="width: 20px">-->
              <!--                    <div class="menuItemTitle  flex-grow-1">-->
              <!--                      {{ $t(`menu.dashboard`) }}-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </router-link>-->
              <!--              <router-link :to="{name: 'sessions', query: {target: 'request',}}" style="text-decoration: none">-->
              <!--                <div class="menuItem">-->
              <!--                  <div class="d-flex">-->
              <!--                    <img src="../../assets/icons/Session.svg" style="width: 20px">-->
              <!--                    <div class="menuItemTitle  flex-grow-1">-->
              <!--                      {{ $t(`menu.session_request`) }}-->

              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </router-link>-->
              <template v-for="(item , i) in renderNav">
                <router-link :to="item.route" :key="i" style="text-decoration: none">
                  <div class="menuItem" :id="item.route.name"
                       :class="prepareActiveRoute(item.route)">
                    <div class="d-flex">
                      <img :src="item.icon" style="width: 20px">
                      <div class="menuItemTitle  flex-grow-1">
                        {{ $t(`menu.${item.key}`) }}
                      </div>
                    </div>
                  </div>
                </router-link>
              </template>
            </div>
          </div>
        </div>
        <div class="text-center" @click="logout" style="cursor: pointer">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <img v-bind="attrs" v-on="on" src="../../assets/icons/SignOut.svg" width="30px" alt="">
            </template>
            <span>
                    خروج از حساب کاربری
            </span>
          </v-tooltip>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
    }),
    renderNav() {
      let temp = []
      console.log('here')
      this.menuItems.forEach((item) => {
        if (item.hasOwnProperty('allowed_platform')) {
          if (this.platFormInfoLS) {
            console.log(item.allowed_platform, '12121')
            if (item.allowed_platform.findIndex(i => i == this.platFormInfoLS.id) != -1) {
              temp.push(item)
            }
          }
        } else {
          temp.push(item)
        }
      })
      return temp
    }
  },
  data() {
    return {
      menuItems: [
        {
          icon: require('../../assets/icons/dashboard.svg'),
          title: 'داشبورد',
          key: 'dashboard',
          route: {
            name: 'dashboard',
          }
        },
        {
          icon: require('../../assets/icons/packages.svg'),
          title: 'درخواست جلسه',
          key: 'session_request',
          route: {name: 'sessions-request',}
        },
        {
          icon: require('../../assets/icons/packages.svg'),
          title: 'خرید‌ جلسه',
          key: "packages",
          route: {
            name: 'packages',
          }
        },

        {
          icon: require('../../assets/icons/Session.svg'),
          title: 'تاریخچه جلسات',
          key: "sessions",
          route: {
            name: 'sessions',
          }
        },
        // {
        //   icon: require('../../assets/icons/tasks.svg'),
        //   title: 'تسک‌ها',
        //   key: "tasks",
        //   route: {
        //     name: 'tasks',
        //   }
        // },
        {
          icon: require('../../assets/icons/tasks.svg'),
          title: 'تاریخچه خرید',
          key: "factor",
          route: {
            name: 'wallet',
          },
        },
        // {
        //   icon: require('../../assets/icons/messengerIcon.svg'),
        //   title: 'پیام‌ها',
        //   key: "messenger",
        //   route: {
        //     name: 'messenger',
        //   }
        // },
        {
          icon: require('../../assets/icons/setting.svg'),
          title: 'پروفایل',
          key: "profile",
          route: {name: 'setting'},
        },
        {
          icon: require('../../assets/icons/tasks.svg'),
          title: 'تست‌ها',
          key: "tests",
          allowed_platform: [3],
          route: {
            name: 'tests',
          }
        },
        // {
        //   icon: require('../../assets/icons/invitees.png'),
        //   title: 'دعوت‌ها',
        //   key: "invitees",
        //   route: {name: 'invitees'}
        // },
        // {
        //   icon: require('../../assets/icons/File.svg'),
        //   title: 'پرونده‌',
        //   key: "file",
        //   route: {
        //     name: 'profile',
        //     query: {
        //       step: 'medical'
        //     }
        //   }
        // },

      ]
    }
  },

  methods: {
    prepareActiveRoute(route) {

      if (route.name == 'messenger' && this.$route.name == 'chat') {
        return "activeRoute"
      }

      return this.$route.name == route.name && 'activeRoute'
    },
    addTestsToManu() {
      if (this.profile) {
        // if (!this.profile.psytest_register) {
        let index = this.menuItems.findIndex(item => item.key == 'tests')
        if (index == -1) {

        }
      }
      // }
    }
  },
  watch: {
    'profile'() {
      this.addTestsToManu()
    }
  },
  mounted() {
    this.addTestsToManu()
  }
}
</script>

<style scoped>
.menuItem {
  margin: 0px auto 15px auto;
  width: 90%;
  padding-top: 10px;
  text-align: center;
  transition: all 0.3s;
  padding-right: 5px;
}

.menuItemTitle {
  color: white;
  text-decoration: none;
  font-size: 15px;
}

.menuItem img {
  margin: 0px auto;
  width: 30px;
}

.activeRoute {
  /*box-shadow: 0 8px 13px 0 rgba(60, 190, 139, 0.38);*/
  background-color: #7fd9d1;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s;
  padding-right: 5px;
}

.navbar {
  overflow: hidden;
  background: #47c8bd;
  z-index: 20;
  position: fixed;
  top: 0px;
  bottom: 0px;
  width: 150px;
  /*border-radius: 20px;*/
  box-shadow: 0 8px 13px 0 rgba(60, 190, 139, 0.38);
}

.rtl_navbar {
  left: 15px;
}

.ltr_navbar {
  right: 0px;
}

.avatarBox {
  border: 2px solid #6bd3ca;
  height: 150px;
  margin-left: -10px;
  margin-right: -10px;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.avatarArea {
  width: 50%;
  margin: 0px auto;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 3px;
  height: 80px;
}

.imageArea {
  background: white;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
}

.imageArea img {
  /*width: 50%;*/
  margin: auto;
}

.logoArea {
  margin: 20px 0px;
  /*padding: 20px;*/
  color: white;
  text-align: center;
}

.logoArea img {
  width: 100%;
}

.settingBox {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid white;
  right: calc(50% - 12px);
  bottom: -12px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: #47c8bd;
}

.settingBox img {
  width: 70%;
  margin: 0px auto;
}


</style>